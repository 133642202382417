        <!-- /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG

                 设备管理页面
        */ -->
<template>
    <el-row>
      <el-col :span="4" style="margin-right: 5px;">
        <el-card style="max-width: 500px">
          <template #header>
            分组管理
          </template>
          <el-tree
            ref="tree"
            :data="treeData"
            :props="defaultProps"
            highlight-current
            node-key="id"
            @node-click="handleNodeClick"
            default-expand-all
            :expand-on-click-node="false"
            @check-change="handleCheckChange"
          />
        </el-card>
      </el-col>
      <el-col :span="19">
        <div class="btnlet">
            <!-- <el-button type="primary" @click="showAddDeviceDialog">添加分组设备</el-button> -->
            <el-button type="primary" size="small" @click="openBatchAddDialog">绑定设备</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="name" label="设备名称" width="180" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-popconfirm title="确定解除绑定?" @confirm="handleDelete(scope.row)">
                <template #reference>
                  <el-button size="mini" type="text">解除绑定</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>


        </el-table>
        <el-pagination
        @current-change="handlePageChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="totalDevices"
        style="float: right; margin-top: 10px;"
      />
      </el-col>
    </el-row>
  
    <el-dialog title="添加分组设备" v-model="isAddDeviceDialogVisible">
    <el-form :model="newDevice">
      <el-form-item label="设备名称">
        <el-input v-model="newDevice.name" @click="openSelectDeviceDialog" readonly/>
      </el-form-item>
      <el-form-item label="所属区域">
        <el-tree-select
          v-model="newDevice.area"
          :data="treeData"
          placeholder="请选择区域"
          check-strictly
          default-expand-all
          value-key="id"
          label-key="name"
          :props="{ label: 'name' }"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="closeAddDeviceDialog">取消</el-button>
      <el-button type="primary" @click="addDevice">确定</el-button>
    </template>
  </el-dialog>
  
    <el-dialog title="选择设备" v-model="isSelectDeviceDialogVisible">
      <el-table :data="paginatedEquipmentData" @row-click="selectEquipment">
        <el-table-column prop="name" label="设备名称" />
      </el-table>
      <el-pagination
        @current-change="handlePageChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="totalEquipment"
      />
      <template #footer>
        <el-button @click="closeSelectDeviceDialog">取消</el-button>
      </template>
    </el-dialog>
    <el-radio-group v-model="energyType" size="small" class="radio-group-right">

                    </el-radio-group>
    <el-dialog title="绑定设备" v-model="isBatchAddDialogVisible" class="batch-add-dialog">
  <div class="transfer-container">

    <div class="equipment-transfer-container">
      <div class="area-select-container" style="margin-bottom: 10px;width: 98%;">
      <el-tree-select
        v-model="selectedArea"
        :data="treeData"
        placeholder="请选择所属区域"
        check-strictly
        default-expand-all
        value-key="id"
        label-key="name"
        :props="{ label: 'name' }"
        class="area-select"
      />
    </div>
    <div class="energy-type-select-container" style="margin-bottom: 10px;width: 98%;">
        <el-select v-model="energyType" placeholder="请选择表类型">
          <el-option label="水表" value="水"></el-option>
          <el-option label="电表" value="电"></el-option>
        </el-select>
      </div>
      <el-transfer
        v-model:modelValue="selectedEquipmentIds"
        :data="equipmentData"
        :titles="['设备列表', '已选设备']"
        filterable
        filter-placeholder="请输入设备名称"
        class="equipment-transfer"
      />
    </div>
  </div>
  <template #footer>
    <el-button @click="closeBatchAddDialog">取消</el-button>
    <el-button type="primary" @click="batchAddDevices">确定</el-button>
  </template>
</el-dialog>


  </template>
  
  <script>
  import axios from 'axios';
  import ApiConfig from "@/APIConfig.js";
  import { ref, onMounted, computed, nextTick, watch } from 'vue';
  import { ElMessage } from 'element-plus';
  
  export default {
    name: 'DeviceManagement',
    setup() {
      const currentPage = ref(1);
      const pageSize = ref(10);
      const totalDevices = ref(0);
      const defaultProps = {
        children: 'children',
        label: 'name'
      };
      const selectedNodeId = ref(null);
      const allDevices = ref([]); // 保存所有设备数据
      const tableData = computed(() => {
        const start = (currentPage.value - 1) * pageSize.value;
        const end = start + pageSize.value;
        return allDevices.value.slice(start, end);
      });
      const treeData = ref([]);
      const equipmentData = ref([]);
      const energyType = ref('电');
      const selectedRow = ref(null);
      const isAddDeviceDialogVisible = ref(false);
      const isSelectDeviceDialogVisible = ref(false);
      const newDevice = ref({
        name: '',
        area: null,
      });
      const isBatchAddDialogVisible = ref(false);
      const selectedArea = ref(null);
      const selectedEquipmentIds = ref([]);
  
      const openBatchAddDialog = () => {
        isBatchAddDialogVisible.value = true;
        fetchAllEquipment(); // 在打开对话框时获取设备列表数据
      };
  
      const closeBatchAddDialog = () => {
        isBatchAddDialogVisible.value = false;
        // 重置选择的区域和设备
        selectedArea.value = null;
        selectedEquipmentIds.value = [];
      };
  
      const batchAddDevices = () => {
        if (selectedArea.value !== null && selectedEquipmentIds.value.length > 0) {
          axios
            .post(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.insertList}`, {
              customRegionId: selectedArea.value,
              energyType: energyType.value,
              equipmentIds: selectedEquipmentIds.value,
            })
            .then((response) => {
              if (response.data && response.data.code === 0) {
                ElMessage.success('批量添加分组设备成功');
                // 这里可以进行其他逻辑操作，比如刷新设备列表等
              } else {
                console.error('批量添加分组设备失败：', response.data.message);
                ElMessage.error('批量添加分组设备失败：' + response.data.message);
              }
            })
            .catch((error) => {
              console.error('批量添加分组设备时出错：', error);
              ElMessage.error('批量添加分组设备时发生错误');
            });
   
          closeBatchAddDialog();
        } else {
          ElMessage.error('请选择所属区域并至少选择一个设备');
        }
      };
  
      const fetchAllLocations = async () => {
        try {
          const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getAlllocation}`);
          if (response.data && response.data.code === 0) {
            const locations = response.data.data;
            treeData.value = transformLocationData(locations);
            if (treeData.value.length > 0) {
              const firstParentId = treeData.value[0].id;
              selectedNodeId.value = firstParentId;
              fetchDevicesAndChildren(firstParentId);
            }
          } else {
            console.error('获取位置信息失败：', response.data.message);
          }
        } catch (error) {
          console.error('获取位置信息时出错：', error);
        }
      };
  
      const transformLocationData = (locations) => {
        const locationMap = {};
        for (const location of locations) {
          locationMap[location.id] = location;
        }
  
        const result = [];
        for (const location of locations) {
          if (location.parent_id !== null) {
            const parent = locationMap[location.parent_id];
            if (parent) {
              if (!parent.children) {
                parent.children = [];
              }
              parent.children.push(location);
            }
          } else {
            result.push(location);
          }
        }
  
        return result;
      };
  
      const handleNodeClick = (node) => {
        selectedNodeId.value = node.id;
        fetchDevicesAndChildren(node.id);
      };
  
      const fetchDevicesAndChildren = async (nodeId) => {
  try {
    const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getById}`, {
      params: { id: nodeId },
    });
    if (response.data && response.data.code === 0) {
      const devices = response.data.data;
      const childNodes = getChildNodes(treeData.value, nodeId);
      const childNodeIds = childNodes.map(node => node.id);
      const childDevices = await fetchDevicesByNodeIds(childNodeIds);
      allDevices.value = [...devices, ...childDevices];
      totalDevices.value = allDevices.value.length;
    } else {
      console.error('获取设备信息失败：', response.data.message);
      ElMessage.error('获取设备信息失败：' + response.data.message);
    }
  } catch (error) {
    console.error('获取设备信息时出错：', error);
    ElMessage.error('获取设备信息时发生错误');
  }
};

  
      const getChildNodes = (nodes, parentId) => {
  let children = [];
  nodes.forEach(node => {
    if (node.id === parentId) {
      if (node.children) {
        children = [...children, ...node.children];
        node.children.forEach(child => {
          children = [...children, ...getChildNodes(node.children, child.id)];
        });
      }
    } else if (node.children) {
      children = [...children, ...getChildNodes(node.children, parentId)];
    }
  });
  return children;
};

  
      const fetchDevicesByNodeIds = async (nodeIds) => {
        const devices = [];
        for (const nodeId of nodeIds) {
          const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getById}`, {
            params: { id: nodeId },
          });
          if (response.data && response.data.code === 0) {
            devices.push(...response.data.data);
          }
        }
        return devices;
      };
  
      const handlePageChange = (page) => {
        currentPage.value = page;
      };
  
      const handleCheckChange = (data, checked, indeterminate) => {
        console.log(data, checked, indeterminate);
      };
  
      const showAddDeviceDialog = () => {
        isAddDeviceDialogVisible.value = true;
        fetchAllEquipment();
      };
  
      const closeAddDeviceDialog = () => {
        isAddDeviceDialogVisible.value = false;
      };
  
      const addDevice = () => {
        if (newDevice.value.name && newDevice.value.area !== null) {
          axios
            .get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.insertequipment}`, {
              params: {
                customRegionId: newDevice.value.area,
                energyType: energyType.value,
                equipmentId: newDevice.value.name,
              }
            })
            .then((response) => {
              if (response.data && response.data.code === 0) {
                  ElMessage.success('设备添加成功');
                fetchDevices(selectedNodeId.value);
              } else {
                console.error('添加设备失败：', response.data.message);
                ElMessage.error('添加失败：' + response.data.message);
              }
            })
            .catch((error) => {
              console.error('添加设备时出错：', error);
              ElMessage.error('添加设备时发生错误');
            });
   
          closeAddDeviceDialog();
        } else {
          ElMessage.error('请填写设备名称并选择所属区域');
        }
      };
  
      const handleEdit = (row) => {
        selectedRow.value = row;
      };
  
      const handleDelete = (row) => {
        // 确保 customRegionId 是整数类型，equipmentId 是字符串类型
        const customRegionId = parseInt(selectedNodeId.value, 10);
        const equipmentId = row.id.toString();
  
        axios
          .delete(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.deleteequipment}?customRegionId=${customRegionId}&equipmentId=${equipmentId}`, {})
          .then((response) => {
            if (response.data && response.data.code === 0) {
              ElMessage.success('设备解除绑定成功');
              fetchDevices(selectedNodeId.value);
            } else {
              console.error('解除设备绑定失败：', response.data.message);
              ElMessage.error('解除失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('解除设备绑定时出错：', error.response || error.message || error);
            ElMessage.error('解除设备绑定时发生错误');
          });
      };
  
      const fetchDevices = (groupId) => {
        axios
          .get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getById}`, {
            params: { id: groupId },
          })
          .then((response) => {
            if (response.data && response.data.code === 0) {
              tableData.value = response.data.data.map((item) => ({
                id: item.id,
                name: item.name,
              }));
            } else {
              console.error('获取设备信息失败：', response.data.message);
              ElMessage.error('获取设备信息失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('获取设备信息时出错：', error);
            ElMessage.error('获取设备信息时发生错误');
          });
      };
  
      let params = {};
      params = {
        energyType: '电',
        projectId: ApiConfig.projectId,
      };
  
      const fetchAllEquipment = () => {
        axios
          .get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getAllNoequipment}`, { params: params })
          .then((response) => {
            if (response.data && response.data.code === 0) {
              const data = response.data.data || [];
              // 格式化设备数据为穿梭框所需的格式
              const formattedEquipmentData = data.map((item) => ({
                key: item.id.toString(), // 设备 ID
                label: item.name, // 设备名称
              }));
              equipmentData.value = formattedEquipmentData;
              totalDevices.value = data.length;
            } else {
              console.error('获取所有设备信息失败：', response.data.message);
              ElMessage.error('获取所有设备信息失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('获取所有设备信息时出错：', error);
            ElMessage.error('获取所有设备信息时发生错误');
          });
      };
  
      const openSelectDeviceDialog = () => {
        if (!isSelectDeviceDialogVisible.value) {
          console.log('打开选择设备弹窗');
          isSelectDeviceDialogVisible.value = true;
          fetchAllEquipment();
        } else {
          console.log('选择设备弹窗已打开');
        }
      };
  
      const selectEquipment = (row) => {
        newDevice.value.name = row.id;
        newDevice.value.id = row.id;
        console.log('选中设备:', row);
        closeSelectDeviceDialog(); // 关闭弹窗
      };
  
      const closeSelectDeviceDialog = () => {
        isSelectDeviceDialogVisible.value = false;
      };
  
      // 在组件挂载时自动获取左侧第一个父级菜单的ID，并根据该ID查询设备信息
      onMounted(() => {
        fetchAllLocations(); // 获取左侧菜单数据
        // 等待左侧菜单数据加载完成后，获取第一个父级菜单的ID
        nextTick(() => {
          if (treeData.value.length > 0) {
            console.log('获取第一个父级菜单的ID', treeData.value[0].id);
            const firstParentId = treeData.value[0].id;
            selectedNodeId.value = firstParentId; // 设置第一个父级菜单的ID
            fetchDevices(firstParentId); // 查询该ID下的设备信息
          }
        });
      });
  
      watch(isSelectDeviceDialogVisible, (newVal) => {
        if (newVal) {
          console.log('对话框被打开');
        } else {
          console.log('对话框被关闭');
        }
      });
  
      return {
        currentPage,
        pageSize,
        totalDevices,
        tableData,
        isAddDeviceDialogVisible,
        isSelectDeviceDialogVisible,
        selectedNodeId,
        newDevice,
        treeData,
        equipmentData,
        showAddDeviceDialog,
        closeAddDeviceDialog,
        addDevice,
        handleEdit,
        handleDelete,
        handleNodeClick,
        handleCheckChange,
        fetchDevices,
        fetchAllEquipment,
        openSelectDeviceDialog,
        selectEquipment,
        closeSelectDeviceDialog,
        selectedRow,
        defaultProps,
        handlePageChange,
        isBatchAddDialogVisible,
        selectedArea,
        selectedEquipmentIds,
        openBatchAddDialog,
        closeBatchAddDialog,
        batchAddDevices,
        energyType,
      };
    }
  };
  </script>
  
<style lang="scss">
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.tree-menu-item {
  display: flex;
  align-items: center;

  .edit-delete-buttons {
    display: none;

    .el-button {
      font-size: 12px;
      margin-left: 2px;
    }
  }

  &:hover .edit-delete-buttons {
    margin-left: 5px;
    display: flex;
  }
}

.btnlet {
  text-align: left;
  padding: 10px 0;
}

.batch-add-dialog {
  width: 60%;
}

.transfer-container {
  display: flex;
  flex-direction: column; /* 在小屏幕上以列方式布局 */
}

.area-select-container,
.equipment-transfer-container {
  width: auto;
  margin-bottom: 20px; /* 设置容器之间的间距 */
}

@media screen and (min-width: 768px) {
  .transfer-container {
    flex-direction: row; /* 在大屏幕上以行方式布局 */
  }

  .area-select-container,
  .equipment-transfer-container {
    width: auto;
    flex: 1; /* 设置两个容器的宽度平分 */
    margin-bottom: 0; /* 取消小屏幕时的间距 */
    margin-right: 20px; /* 设置容器之间的间距 */
  }

  .equipment-transfer-container {
    margin-right: 0; /* 取消设备列表和已选设备之间的间距 */
  }
}

.equipment-transfer {
  width: auto;
  max-height: 350px;
  overflow-y: auto;
  white-space: nowrap; /* 阻止文本换行 */
}
.el-transfer-panel{width: 300px;}
</style>
  