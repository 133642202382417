        <!-- /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG
        */ -->
<template>
  <div class="energy-report-container">
    <el-row :gutter="20">
      <!-- 左侧树控件 -->
      <el-col :span="6">
        <el-card>
          <template v-slot:header>
            <span class="card-header">用能集抄</span>
          </template>

          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="分组" name="group">
              <!-- 按分组 -->
              <!-- 添加搜索输入框 -->
              <el-input
                placeholder="模糊搜索"
                v-model="searchQuery"
                class="tree-search"
                clearable
              />
              <el-tree
                ref="tree"
                :data="treeData"
                :props="defaultProps"
                highlight-current
                node-key="id"
                @node-click="handleNodeClick"
                default-expand-all
                :expand-on-click-node="false"
                show-checkbox
                @check-change="handleCheckChange"
                :filter-node-method="filterNode"
              />
              <!-- 按分组end -->
            </el-tab-pane>

            <el-tab-pane label="设备" name="equipment">
              <!-- 按设备 -->
              <el-select
                v-model="selectedDevices"
                multiple
                clearable
                collapse-tags
                placeholder="请选择设备"
                popper-class="custom-header"
                :max-collapse-tags="1"
                filterable
              >
                <template #header>
                  <div class="header-container">
                    <el-checkbox
                      v-model="checkAllDevices"
                      :indeterminate="indeterminateDevices"
                      @change="handleCheckAllDevices"
                    >
                      全选
                    </el-checkbox>
                    <el-radio-group
                      v-model="energyType"
                      size="small"
                      class="radio-group-right"
                      @change="fetchDeviceData"
                    >
                      <el-radio-button label="电" value="电" />
                      <el-radio-button label="水" value="水" />
                    </el-radio-group>
                  </div>
                </template>
                <el-option
                  v-for="device in devices"
                  :key="device.id"
                  :label="device.name"
                  :value="device.id"
                />
              </el-select>
              <!-- 按设备end -->
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>

      <!-- 右侧查询条件和表格 -->
      <el-col :span="18">
        <div class="query-container">
          <!-- 查询条件 -->
          <el-form :model="queryForm" inline>
            <el-form-item class="left-align">
              <el-select
                v-model="energyType"
                placeholder="选择用能类型"
                size="small"
                style="width: 45px; margin-right: 10px"
                @change="fetchDeviceData"
              >
                <el-option label="水" value="水"></el-option>
                <el-option label="电" value="电"></el-option>
              </el-select>
              <el-select
                v-model="queryGranularity"
                placeholder="选择颗粒度"
                size="small"
                style="width: 50px; margin-right: 10px"
              >
                <el-option label="分" value="minute"></el-option>
                <el-option label="时" value="hour"></el-option>
                <el-option label="天" value="day"></el-option>
                <el-option label="月" value="month"></el-option>
              </el-select>
              <el-date-picker
                v-if="queryGranularity === 'month'"
                v-model="startMonth"
                type="month"
                placeholder="开始月份"
                size="small"
                style="width: 130px"
              />
              <el-date-picker
                v-if="queryGranularity === 'month'"
                v-model="endMonth"
                type="month"
                placeholder="结束月份"
                size="small"
                style="width: 120px; margin-left: 10px"
              />
              <el-select
                v-if="queryGranularity === 'month'"
                v-model="readTime"
                placeholder="选择时间"
                size="small"
                style="width: 80px; margin-left: 10px"
              >
                <el-option
                  v-for="minute in 288"
                  :key="minute"
                  :label="formatMinuteOption(minute - 1, true)"
                  :value="formatMinuteOption(minute - 1, true)"
                ></el-option>
              </el-select>
              <el-date-picker
                v-if="queryGranularity !== 'month'"
                v-model="startDate"
                type="date"
                placeholder="开始日期"
                size="small"
                style="width: 100px"
              />
              <el-select
                v-if="
                  queryGranularity === 'hour' || queryGranularity === 'minute'
                "
                v-model="startTime"
                placeholder="开始时间"
                size="small"
                style="width: 55px; margin-left: 10px"
              >
                <el-option
                  v-for="hour in 24"
                  :key="hour"
                  :label="`${hour.toString().padStart(2, '0')}h`"
                  :value="hour"
                ></el-option>
              </el-select>
              <el-select
                v-if="queryGranularity === 'minute'"
                v-model="startMinute"
                placeholder="开始分钟"
                size="small"
                style="width: 70px; margin-left: 10px"
              >
                <el-option
                  v-for="minute in 12"
                  :key="minute"
                  :label="formatMinuteOption(minute - 1)"
                  :value="(minute - 1) * 5"
                ></el-option>
              </el-select>
              <el-date-picker
                v-if="queryGranularity !== 'month'"
                v-model="endDate"
                type="date"
                placeholder="结束日期"
                size="small"
                style="width: 100px; margin-left: 10px"
              />
              <el-select
                v-if="
                  queryGranularity === 'hour' || queryGranularity === 'minute'
                "
                v-model="endTime"
                placeholder="结束时间"
                size="small"
                style="width: 55px; margin-left: 5px"
              >
                <el-option
                  v-for="hour in 24"
                  :key="hour"
                  :label="`${hour.toString().padStart(2, '0')}h`"
                  :value="hour"
                ></el-option>
              </el-select>
              <el-select
                v-if="queryGranularity === 'minute'"
                v-model="endMinute"
                placeholder="结束分钟"
                clearable
                size="small"
                style="width: 65px; margin-left: 10px"
              >
                <el-option
                  v-for="minute in 12"
                  :key="minute"
                  :label="formatMinuteOption(minute - 1)"
                  :value="(minute - 1) * 5"
                ></el-option>
              </el-select>
              <el-button
                type="primary"
                @click="fetchTableData"
                style="margin-left: 10px"
                size="small"
                >查询</el-button
              >
              <el-button type="primary" @click="exportToExcel" size="small"
                >导出</el-button
              >
              <el-checkbox v-model="showIndications" style="margin-left: 10px"
                >读数</el-checkbox
              >
              <el-checkbox v-model="showUsage" style="margin-left: -15px"
                >用量</el-checkbox
              >
            </el-form-item>
          </el-form>
        </div>
        <el-col :span="24" class="chart-controls" v-if="showChart">
          <el-button-group>
            <el-button
              :type="yearlyBarButtonType"
              @click="toggleChartType('柱状')"
              >柱状</el-button
            >
            <el-button
              :type="yearlyLinButtonType"
              @click="toggleChartType('曲线')"
              >曲线</el-button
            >
          </el-button-group>
        </el-col>

        <v-chart
          ref="chartRef"
          v-if="showChart"
          :option="chartOptions"
          style="width: 100%; height: 400px"
        ></v-chart>

        <!-- 表格 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          :summary-method="getSummaries"
          show-summary
          border
        >
          <el-table-column
            prop="deviceName"
            :label="nameLabel"
            width="150"
            fixed
          />
          <template v-for="header in headers" :key="header.label">
            <el-table-column :label="header.label">
              <el-table-column
                v-if="showIndications"
                :prop="header.readingProp"
                label="读数"
              />
              <el-table-column
                v-if="showUsage"
                :prop="header.usageProp"
                label="用量"
              />
            </el-table-column>
          </template>
          <el-table-column prop="totalUsage" label="合计" />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from "echarts/components";
import { ElMessage } from "element-plus";
import axios from "axios";
import ApiConfig from "@/APIConfig.js";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
]);

export default {
  name: "EnergyUsageReport",
  data() {
    return {
      searchQuery: "", // 搜索查询
      nameLabel: "分组名称", // 动态表格列标题
      devices: [], // 新增: 设备数据
      checkAllDevices: false, // 新增: 设备全选
      indeterminateDevices: false, // 新增: 设备全选不确定状态
    };
  },
  created() {
    this.fetchDeviceData(); // 新增: 调用设备数据的获取方法
  },
  watch: {
    searchQuery(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    handleClick() {
      if (this.activeName === "group") {
        this.nameLabel = "设备名称";
      } else if (this.activeName === "equipment") {
        this.nameLabel = "分组名称";
      }
    },
    handleCheckAllDevices(val) {
      this.selectedDevices = val ? this.devices.map((device) => device.id) : [];
      this.indeterminateDevices = false;
    },
    async fetchDeviceData() {
      let params = {};
      params = {
        energyType: this.energyType,
        projectId: ApiConfig.projectId,
      };
      // 在切换用能类型之前清空已选设备
      this.selectedDevices = [];
      this.checkAllDevices = false;
      this.indeterminateDevices = false;
      try {
        const response = await axios.get(
          ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAllequipment,
          { params: params }
        );
        if (response.data.code === 0) {
          this.devices = response.data.data;
        } else {
          this.$message.error("获取设备数据失败");
        }
      } catch (error) {
        this.$message.error("获取设备数据失败");
        console.error(error);
      }
    },
  },
  setup() {
    const startDate = ref(null);
    const startMonth = ref(null);
    const startTime = ref(0);
    const startMinute = ref(0);
    const endDate = ref(null);
    const endMonth = ref(null);
    const endTime = ref(23);
    const endMinute = ref(55);
    const readTime = ref("00:10");
    const queryGranularity = ref("minute");
    const energyType = ref("电");
    const showIndications = ref(true);
    const showUsage = ref(true);
    const treeData = ref([]);
    const defaultProps = ref({
      children: "children",
      label: "name",
    });
    const queryForm = ref({
      date: "",
    });
    const tableData = ref([]);
    const headers = ref([]);
    const loading = ref(false);
    const selectedNodes = ref([]);
    const selectedDevices = ref([]);
    const tree = ref(null);
    const chartOptions = ref({});
    const chartType = ref("bar");
    const yearlyBarButtonType = ref("primary");
    const yearlyLinButtonType = ref("default");
    const showChart = ref(false);
    const activeName = ref("group");

    const filterNode = (value, data) => {
      if (!value) return true;
      return data.name.toLowerCase().includes(value.toLowerCase());
    };

    // 监控 showIndications 和 showUsage 的变化
    watch(showIndications, () => {
      updateChartOptions();
    });

    watch(showUsage, () => {
      updateChartOptions();
    });

    const fetchTreeData = async () => {
      try {
        loading.value = true;
        const response = await axios.get(
          ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAlllocation
        );
        treeData.value = convertToTree(response.data.data);
      } catch (error) {
        ElMessage.error("获取区域数据失败");
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const handleNodeClick = (node) => {
      console.log("Node clicked:", node);
    };

    const handleCheckChange = () => {
      const selectedNodesData = tree.value.getCheckedNodes();
      selectedNodes.value = selectedNodesData.map((node) => ({
        id: node.id,
        treeNodeId: node.$treeNodeId,
        label: node.name, // 确保正确属性
      }));
      console.log("Selected nodes:", selectedNodes.value);
    };

    const formatMinuteOption = (minute, full = false) => {
      const hours = Math.floor(minute / 12);
      const minutes = (minute % 12) * 5;
      return full
        ? `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
          )}`
        : `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
          )}`;
    };

    const formatDate = (date, type = "datetime") => {
      if (!date) return null;
      const d = new Date(date);
      if (isNaN(d.getTime())) return null;
      if (type === "date") {
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(d.getDate()).padStart(2, "0")}`;
      } else if (type === "month") {
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
          2,
          "0"
        )}`;
      }
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(d.getDate()).padStart(2, "0")} ${String(
        d.getHours()
      ).padStart(2, "0")}:${String(d.getMinutes()).padStart(2, "0")}`;
    };

    const formatHeaderLabel = (timestamp) => {
      if (!timestamp) return "";

      const [dateTime] = timestamp.split("_");
      const [date, time] = dateTime.split(" ");

      if (!time || time === "00:00") return date;

      return `${date} ${time}`;
    };

    const generateHeaders = (apiData) => {
      const headersSet = new Set();
      apiData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== "deviceName") {
            const timestamp = key.split("_").slice(0, 3).join("_");
            headersSet.add(timestamp);
          }
        });
      });

      const headers = Array.from(headersSet).map((timestamp) => ({
        label: formatHeaderLabel(timestamp),
        readingProp: `${timestamp}_reading`,
        usageProp: `${timestamp}_usage`,
      }));

      return headers;
    };

    const updateHeaders = (apiData) => {
      headers.value = generateHeaders(apiData);
    };

    const transformData = (data) => {
      const transformedData = data.map((item) => {
        const transformedItem = { deviceName: item.deviceName };
        headers.value.forEach((header) => {
          transformedItem[header.readingProp] = parseFloat(
            item[header.readingProp]
          )
            ? parseFloat(item[header.readingProp]).toFixed(2)
            : "0.00";
          transformedItem[header.usageProp] = parseFloat(item[header.usageProp])
            ? parseFloat(item[header.usageProp]).toFixed(2)
            : "0.00";
        });
        transformedItem.totalUsage = headers.value
          .reduce((acc, header) => {
            const usageValue = parseFloat(transformedItem[header.usageProp]);
            if (!isNaN(usageValue)) {
              return acc + usageValue;
            }
            return acc;
          }, 0)
          .toFixed(2);
        return transformedItem;
      });
      return transformedData;
    };

    const fetchTableData = async () => {
      let apiurl;
      let requestData = buildRequestData();
      if (activeName.value === "group") {
        if (selectedNodes.value.length === 0) {
          ElMessage.warning("请先选择一个或多个分组");
          return;
        }
        apiurl = ApiConfig.endpoints.getCentralizedCopying;
        requestData.customRegionIds = selectedNodes.value.map(
          (node) => node.id
        );
      } else if (activeName.value === "equipment") {
        if (selectedDevices.value.length === 0) {
          ElMessage.warning("请先选择一个或多个设备");
          return;
        }
        apiurl = ApiConfig.endpoints.getCentralizedCopyingEquipment;
        requestData.equipmentIds = selectedDevices.value;
      }

      const url = `${ApiConfig.apiBaseUrl}${apiurl}`;
      console.log("Request URL:", url);
      console.log("Request Data:", requestData);

      // const requestData = buildRequestData();
      if (!requestData) return;

      try {
        loading.value = true;
        const response = await axios.post(url, requestData);
        console.log("API response data:", response.data);
        if (response.data) {
          if (
            response.data.code === 0 &&
            response.data.message === "该类型设备数为0"
          ) {
            const nodeNames = selectedNodes.value
              .map((node) => node.label)
              .join(", ");
            ElMessage.warning(`${nodeNames} 暂无数据`);
            return;
          }

          updateHeaders(response.data.data);
          tableData.value = transformData(response.data.data);
          updateChartOptions(); // 更新表格数据后更新图表
          showChart.value = true; // 显示图表
        } else {
          ElMessage.error(response.data.message);
          showChart.value = false; // 隐藏图表
        }
      } catch (error) {
        ElMessage.error("获取数据失败");
        console.error(error);
        showChart.value = false; // 隐藏图表
      } finally {
        loading.value = false;
      }
    };

    const exportToExcel = async () => {
      let apiurl;
      let requestData = buildRequestData();

      if (activeName.value === "group") {
        if (selectedNodes.value.length === 0) {
          ElMessage.warning("请至少选择一个组区域");
          return;
        }
        apiurl = ApiConfig.endpoints.getCentralizedCopyingExcel;
        requestData.customRegionIds = selectedNodes.value.map(
          (node) => node.id
        );
      } else if (activeName.value === "equipment") {
        if (selectedDevices.value.length === 0) {
          ElMessage.warning("请先选择一个或多个设备");
          return;
        }
        apiurl = ApiConfig.endpoints.getCentralizedCopyingEquipmentExcel;
        requestData.equipmentIds = selectedDevices.value;
      }

      const url = `${ApiConfig.apiBaseUrl}${apiurl}`;
      console.log("Request URL:", url);
      console.log("Request Data:", requestData);

      if (!requestData) return;

      try {
        loading.value = true;
        console.log("请求数据", requestData);

        const response = await axios.post(url, requestData, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "用能集抄.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        ElMessage.error("导出Excel失败");
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums = [];
      const unit = energyType.value === "水" ? "m³" : "kW·h";
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = `总计/${unit}`;
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0)
            .toFixed(2);
        } else {
          sums[index] = "";
        }
      });
      return sums;
    };

    const matchTimestamp = (timestamp, type) => {
      if (!timestamp) return "";

      const [date, time] = timestamp.split(" ");
      if (!time) return `${date}_${type}`;

      const [hour, minute] = time.split(":");
      const formattedHour = hour ? hour.padStart(2, "0") : "00";
      const formattedMinute = minute ? minute.padStart(2, "0") : "00";
      const formattedTime = `${formattedHour}_${formattedMinute}`;

      return `${date}_${formattedTime}_${type}`;
    };

    const buildRequestData = () => {
      // 检查是否选择了分组或设备
      if (
        selectedNodes.value.length === 0 &&
        selectedDevices.value.length === 0
      ) {
        ElMessage.error("请选择一个或多个设备组或分子组或设备");
        return null;
      }

      let requestData = {
        queryGranularity: queryGranularity.value,
        energyType: energyType.value,
        readTime: readTime.value,
      };

      // 根据时间粒度设置请求数据
      if (queryGranularity.value === "minute") {
        requestData = {
          ...requestData,
          startDate: formatDate(startDate.value, "date"),
          endDate: formatDate(endDate.value, "date"),
          startMonth: formatDate(startMonth.value, "month"),
          endMonth: formatDate(endMonth.value, "month"),
          startTime: `${String(startTime.value).padStart(2, "0")}h`,
          endTime: `${String(endTime.value).padStart(2, "0")}h`,
          startMinute: `${String(startMinute.value).padStart(2, "0")}m`,
          endMinute: `${String(endMinute.value).padStart(2, "0")}m`,
        };
      } else if (queryGranularity.value === "hour") {
        requestData = {
          ...requestData,
          startDate: formatDate(startDate.value, "date"),
          endDate: formatDate(endDate.value, "date"),
          startMonth: formatDate(startMonth.value, "month"),
          endMonth: formatDate(endMonth.value, "month"),
          startTime: `${String(startTime.value).padStart(2, "0")}h`,
          endTime: `${String(endTime.value).padStart(2, "0")}h`,
          startMinute: "",
          endMinute: "",
        };
      } else if (queryGranularity.value === "day") {
        requestData = {
          ...requestData,
          startDate: formatDate(startDate.value, "date"),
          endDate: formatDate(endDate.value, "date"),
          startMonth: formatDate(startMonth.value, "month"),
          endMonth: formatDate(endMonth.value, "month"),
          startTime: "",
          endTime: "",
          startMinute: "",
          endMinute: "",
        };
      } else if (queryGranularity.value === "month") {
        requestData = {
          ...requestData,
          startMonth: formatDate(startMonth.value, "month"),
          endMonth: formatDate(endMonth.value, "month"),
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
          startMinute: "",
          endMinute: "",
        };
      }

      // 根据选择的分组或设备设置请求数据

      if (activeName.value === "group" && selectedNodes.value.length > 0) {
        requestData.customRegionIds = selectedNodes.value.map(
          (node) => node.id
        );
      } else if (
        activeName.value === "equipment" &&
        selectedDevices.value.length > 0
      ) {
        requestData.equipmentIds = selectedDevices.value;
      }

      return requestData;
    };

    const updateChartOptions = () => {
      const seriesData = [];
      const unit = energyType.value === "水" ? "m³" : "kW·h";
      if (showIndications.value) {
        const seriesDataReading = tableData.value.map((item) => ({
          name: `${item.deviceName} 读数`,
          type: chartType.value,
          smooth: true,
          data: headers.value.map(
            (header) => item[header.readingProp] || "0.00"
          ),
        }));
        seriesData.push(...seriesDataReading);
      }

      if (showUsage.value) {
        const seriesDataUsage = tableData.value.map((item) => ({
          name: `${item.deviceName} 用量`,
          type: chartType.value,
          smooth: true,
          data: headers.value.map((header) => item[header.usageProp] || "0.00"),
        }));
        seriesData.push(...seriesDataUsage);
      }

      chartOptions.value = {
        title: {
          text: "用能数据分析",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let tooltipText = "";
            params.forEach((item) => {
              tooltipText += `${item.marker} ${item.seriesName}: ${item.value} ${unit}<br/>`;
            });
            return tooltipText;
          },
        },
        legend: {
          data: seriesData.map((item) => item.name),
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: headers.value.map((header) => header.label),
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: `{value} ${energyType.value === "水" ? "m³" : "kW·h"}`,
          },
        },
        series: seriesData.length
          ? seriesData
          : [
              {
                name: "No Data",
                type: chartType.value,
                smooth: true,
                data: headers.value.map(() => "0.00"),
              },
            ],
      };

      nextTick(() => {
        this.$refs.chartRef.setOption(chartOptions.value);
      });
    };

    const toggleChartType = (type) => {
      if (type === "柱状") {
        chartType.value = "bar";
        yearlyBarButtonType.value = "primary";
        yearlyLinButtonType.value = "default";
      } else if (type === "曲线") {
        chartType.value = "line";
        yearlyBarButtonType.value = "default";
        yearlyLinButtonType.value = "primary";
      }
      updateChartOptions(); // 切换图表类型后更新图表
    };

    const convertToTree = (data) => {
      const map = {};
      data.forEach((item) => {
        map[item.id] = { ...item, label: item.name }; // 确保使用正确的属性名称
      });

      const tree = [];
      data.forEach((item) => {
        if (item.parent_id === null) {
          tree.push(map[item.id]);
        } else {
          const parent = map[item.parent_id];
          if (parent) {
            parent.children = parent.children || [];
            parent.children.push(map[item.id]);
          }
        }
      });

      return tree;
    };

    onMounted(() => {
      fetchTreeData();
    });

    return {
      startDate,
      startMonth,
      startTime,
      startMinute,
      endDate,
      endMonth,
      endTime,
      endMinute,
      energyType,
      readTime,
      queryGranularity,
      showIndications,
      showUsage,
      treeData,
      defaultProps,
      queryForm,
      tableData,
      headers,
      loading,
      selectedNodes,
      selectedDevices,
      tree,
      chartOptions,
      yearlyBarButtonType,
      yearlyLinButtonType,
      showChart,
      handleNodeClick,
      handleCheckChange,
      formatMinuteOption,
      fetchTableData,
      exportToExcel,
      getSummaries,
      formatDate,
      matchTimestamp,
      buildRequestData,
      updateChartOptions,
      toggleChartType,
      filterNode,
      activeName,
    };
  },
};
</script>

<style scoped>
.energy-report-container {
  padding: 20px;
}

.query-container {
  margin-bottom: 20px;
}

.left-align {
  display: flex;
  align-items: center;
}

.card-header {
  font-weight: bold;
}

.chart-controls {
  text-align: right;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.radio-group-right {
  margin-left: auto;
}
</style>
