        <!-- /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG
        */ -->
<template>
  <el-container>
    <el-aside width="200px">
      <el-header>
        <span>分组结构</span>
        <el-dropdown @command="handleCommand">
          <el-button type="text" class="el-icon-more">操作</el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="add">添加</el-dropdown-item>
              <!-- <el-dropdown-item command="edit">编辑</el-dropdown-item>
              <el-dropdown-item command="delete">删除</el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-header>
      <el-tree
        ref="departmentTree"
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        highlight-current
        default-expand-all
        :expand-on-click-node="false"
        @node-click="handleNodeClick"
        @check-change="handleCheckChange"
      />
    </el-aside>
    <el-container>
      <el-main>
        <el-table :data="filteredDepartments" style="width: 100%">
          <el-table-column prop="id" label="序号" width="90"></el-table-column>
          <el-table-column prop="name" label="分组名称"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" @click="openDialog('edit', scope.$index, scope.row)">编辑</el-button>
              <el-popconfirm title="确定删除记录?" @confirm="confirmDelete(scope.row.id)">
                <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <el-dialog :title="dialogTitle" v-model="dialogVisible" draggable resizable>
      <el-form :model="form">
        <el-form-item label="分组名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="选择分组">
          <el-tree-select
            v-model="form.parentId"
            :data="treeData"
            placeholder="请选择分组"
            check-strictly
            default-expand-all
            value-key="id"
            label-key="name"
            :props="{ label: 'name', children: 'children' }"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveDepartment">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </el-container>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import ApiConfig from '@/APIConfig.js';

export default {
  name: 'DepartmentManagement',
  setup() {
    const treeData = ref([]);
    const flatTreeData = ref([]);
 
    const filteredDepartments = ref([]);
    const dialogVisible = ref(false);
    const dialogTitle = ref('');
    const form = ref({
      parentId: null,
      name: '',
    });
    const editingIndex = ref(-1);
    const selectedNode = ref(null);
    const loading = ref(false);
    const nextId = ref(25);
    const departmentTree = ref(null); // 添加 departmentTree 以避免未定义错误
    const defaultProps = { // 添加 defaultProps 以避免未定义错误
      children: 'children',
      label: 'name',
    };

    const fetchTreeData = async () => {
      try {
        loading.value = true;
        const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getAlllocation}`);
        treeData.value = convertToTree(response.data.data);
        flatTreeData.value = flattenTree(response.data.data);
      } catch (error) {
        ElMessage.error('获取区域数据失败');
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const convertToTree = (data) => {
      const idMap = data.reduce((acc, item) => {
        acc[item.id] = { ...item, children: [] };
        return acc;
      }, {});

      const tree = [];
      data.forEach((item) => {
        if (item.parent_id === null) {
          tree.push(idMap[item.id]);
        } else {
          idMap[item.parent_id].children.push(idMap[item.id]);
        }
      });
      return tree;
    };

    const flattenTree = (data) => {
      const result = [];
      const flatten = (node) => {
        result.push({ id: node.id, name: node.name });
        if (node.children && node.children.length) {
          node.children.forEach(flatten);
        }
      };
      data.forEach(flatten);
      return result;
    };

    const handleNodeClick = async (data, node) => {
      selectedNode.value = node;
      console.log('Selected node:', data);
      fetchDepartmentsByParentId(data.id);
    };

    const fetchDepartmentsByParentId = async (parentId) => {
      console.log('Fetching departments for parentId:', parentId);
      try {
        const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getDepartmentsByParentId}?parentId=${parentId}`);
        filteredDepartments.value = response.data.data;
      } catch (error) {
        ElMessage.error('获取分组数据失败');
        console.error(error);
      }
    };

    const handleCheckChange = () => {
      const checkedNodes = departmentTree.value.getCheckedNodes();
      selectedNode.value = checkedNodes.length ? checkedNodes[0] : null;
    };

    const handleCommand = (command) => {
      if (command === 'add') {
        if (selectedNode.value) {
          form.value.parentId = selectedNode.value.data.id;
        } else {
          form.value.parentId = null;
        }
        openDialog('add');
      } else if (command === 'edit') {
        if (selectedNode.value) {
          openDialog('edit', null, selectedNode.value.data);
        } else {
          ElMessage.warning('请先选择一个节点');
        }
      } else if (command === 'delete') {
        if (selectedNode.value) {
          deleteDepartment(selectedNode.value.data.id);
        } else {
          ElMessage.warning('请先选择一个节点');
        }
      }
    };

    const openDialog = (type, index = -1, row = {}) => {
      if (type === 'add') {
        dialogTitle.value = '新增分组';
        form.value = {
          parentId: selectedNode.value ? selectedNode.value.data.id : null,
          name: '',
        };
        editingIndex.value = -1;
      } else if (type === 'edit') {
        dialogTitle.value = '编辑分组';
        form.value = {
          id: row.id,
          parentId: row.parent_id,
          name: row.name,
        };
        editingIndex.value = index;
      }
      dialogVisible.value = true;
    };

    const saveDepartment = async () => {
  if (editingIndex.value === -1) {
    try {
      const response = await axios.post(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.newDepartment}`, {
        name: form.value.name,
        parent_id: form.value.parentId,
      });

      if (response.data && response.data.code === 0 && response.data.data === 1) {
        const newDepartment = {
          id: nextId.value++,
          parent_id: form.value.parentId,
          name: form.value.name,
          children: [],
        };

        if (form.value.parentId) {
          const parentNode = findNodeById(treeData.value, form.value.parentId);
          if (parentNode) {
            parentNode.children.push(newDepartment);
          }
        } else {
          treeData.value.push(newDepartment);
        }

        ElMessage.success('新增成功');
        fetchTreeData();
        if (form.value.parentId) {
          fetchDepartmentsByParentId(form.value.parentId);
        } else {
          fetchTreeData();
        }
      } else {
        ElMessage.error('新增失败');
      }
    } catch (error) {
      ElMessage.error('新增失败');
      console.error(error);
    }
  } else {
    try {
      const response = await axios.put(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.updateDepartment}`, {
        id: form.value.id,
        name: form.value.name,
        parent_id: form.value.parentId,
      });

      if (response.data && response.data.message === "操作成功" && response.data.data === 1) {
        if (selectedNode.value) {
          selectedNode.value.data.name = form.value.name;
          selectedNode.value.data.parentId = form.value.parentId;
        }
        ElMessage.success('编辑成功');
        fetchTreeData();
        const parentId = form.value.parentId ? form.value.parentId : selectedNode.value.data.id;
        fetchDepartmentsByParentId(parentId);
      } else {
        ElMessage.error('编辑失败');
      }
    } catch (error) {
      ElMessage.error('编辑失败');
      console.error(error);
    }
  }
  dialogVisible.value = false;
};

const deleteDepartment = async (id) => {
  try {
    const response = await axios.delete(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.deleteDepartment}`, {
      params: { id: id },
    });

    if (response.data && response.data.code === 0 && response.data.data === 1) {
      ElMessage.success('删除成功');
      fetchTreeData();
      filteredDepartments.value = filteredDepartments.value.filter(department => department.id !== id);
    } else {
      ElMessage.error('删除失败');
      console.error('Unexpected response format or values:', response.data);
    }
  } catch (error) {
    ElMessage.error('删除失败');
    console.error(error);
  }
};

const confirmDelete = async (id) => {
  await deleteDepartment(id);
};

const findNodeById = (nodes, id) => {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }
    if (node.children && node.children.length > 0) {
      const found = findNodeById(node.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

onMounted(() => {
  fetchTreeData();
});

return {
  departmentTree,
  treeData,
  flatTreeData,
  defaultProps,
  filteredDepartments,
  dialogVisible,
  dialogTitle,
  form,
  selectedNode,
  loading,
  handleNodeClick,
  handleCheckChange,
  handleCommand,
  openDialog,
  saveDepartment,
  deleteDepartment,
  confirmDelete,
  fetchTreeData,
  nextId,
};
  },
};
</script>

<style scoped>
.el-icon-more {
  margin-bottom: 20px;
}
.dialog-footer {
  text-align: right;
}
.el-aside {
  background-color: #f9f9f9;
  padding: 10px;
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  --el-header-height: 40px;
}
.el-dropdown {
  margin-top: 20px;
}
.area-select {
  width: 100%;
}
</style>
