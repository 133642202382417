<template>
  <div class="common-layout">
    <el-container>
      <el-main>
        <el-card shadow="always">
          <div class="query-container">
            <el-select
                v-model="energyTypeStrategy"
                placeholder="选择策略"
                style="width: 150px; margin-right: 10px"
                clearable
                filterable
            >
              <el-option
                  v-for="item in optionsStrategy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
            <el-select
                v-model="energyTypeCustomRegion"
                placeholder="选择分组"
                style="width: 170px; margin-right: 10px"
                clearable
                filterable
            >
              <el-option
                  v-for="item in optionsCustomRegion"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
            <el-select
                v-model="energyTypeEquipment"
                placeholder="选择设备"
                style="width: 170px; margin-right: 10px"
                clearable
                filterable
                @visible-change="handleDropdownVisibleChange"
            >
              <el-option
                  v-for="item in optionsEquipment"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
            <el-date-picker
                v-model="dateRange"
                value-format="YYYY-MM-DD"
                type="date"
                placeholder="选择日期"
                style="margin-right: 10px"
            />
            <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakValleyLevelDay">查询</el-button>
          </div>
          <!-- 编辑弹窗 -->
          <el-dialog
              title="编辑数据"
              v-model="addDialogVisible"
              :destroy-on-close="true"
              draggable
              width="20%"
          >
            <el-form :style="{ display: 'flex', 'justify-content': 'center' }">
              <el-form-item label="用能">
                <el-input-number
                    v-model="dialogData.value"
                    :precision="3"
                    :step="0.1"
                />
              </el-form-item>
            </el-form>


            <template v-slot:footer>
              <el-button @click="addDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="updateStrategyPeakValleyLevel">保存</el-button>
            </template>
          </el-dialog>
          <!-- 表格 -->
          <el-table
              :data="currentTableData"
              style="width: 100%"
              @row-click="handleRowClick"
              show-selection
              show-summary
          >
            <el-table-column prop="peakValleyLevelStrategyName" label="策略" align="center" width="100px" />
            <el-table-column prop="nyr" label="日期" align="center"  />
            <el-table-column prop="energy" label="用能" align="center" />
            <el-table-column prop="createTime" label="创建时间" align="center"  />
            <el-table-column prop="lastTime" label="更新时间" align="center"  />
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                    type="text"
                    size="small"
                    @click="showEditDialog(scope.row)"
                >编辑</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import {ref, watch, onMounted} from "vue";
import {
  Search
} from "@element-plus/icons-vue";
import apiModule from "@/APIConfig";
import axios from "axios";
import {ElMessage} from "element-plus";
//全部策略
const optionsStrategy = ref([]);
//全部分组
const optionsCustomRegion = ref([]);
//分组下的设备
const optionsEquipment = ref([])

const energyTypeStrategy = ref()
const energyTypeCustomRegion = ref()
const energyTypeEquipment = ref()

//编辑弹窗
const addDialogVisible = ref(false)
//弹窗数据
const dialogData = ref({
  value:""
});


// 日期控件状态
const dateRange = ref("");

const dialogVisible = ref(false);//图表对话框
// 用于存储选中的表格行
const selectedData = ref([]);
//表格数据
const currentTableData = ref([])


// 表格选中项变化时触发
const handleRowClick = (rows) => {
  selectedData.value = rows
};

//设备下拉框显示时触发
const handleDropdownVisibleChange = async (visible) => {
  if (visible) {
    // 当下拉框显示时触发
    await fetchDeviceDataLocation(energyTypeCustomRegion.value);
  }
};

//编辑按钮触发
const showEditDialog = (row) => {
  addDialogVisible.value = true
  dialogData.value.value = Number(row.energy)
};

//查询
const queryCustomRegionPeakValleyLevelDay = async () => {
  try {
    let params = {
      equipmentId: energyTypeEquipment.value,
      nyr: dateRange.value,
      strategyId:  Number(energyTypeStrategy.value),
      page: 1,
      limit: 1000
    };
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.queryStrategyPeakValleyLevelVariableEnergyHour}`, {
      params: params,  // 通过 params 字段传递查询参数
      headers: {
        'Content-Type': 'application/json',
      },
    });
    currentTableData.value = response.data.data
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
}
//更新记录
const updateStrategyPeakValleyLevel = async () => {
  addDialogVisible.value = false
  try {
    let params = {
      variableId: selectedData.value.variableId,
      strategyPeakValleyLevelId:  selectedData.value.strategyPeakValleyLevelId,
      nyr: selectedData.value.nyr,
      energyValue: dialogData.value.value
    };
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.updateStrategyPeakValleyLevelVariableEnergyHour}`, {
      params: params,  // 通过 params 字段传递查询参数
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0){
      ElMessage.success(response.data.message)
    }else {
      ElMessage.error(response.data.message)
    }

    return response.data.message;
  } catch (err) {
    ElMessage.error("更新失败")
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
}
// 页面加载时触发接口调用
onMounted(async () => {
  await getAlllocation();
  await strategyAll();
});
//获取分组下全部设备
const fetchDeviceDataLocation = async (id) => {
  try {
    let params = {
      id: id
    };
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getById}`, {
      params: params,  // 通过 params 字段传递查询参数
      headers: {
        'Content-Type': 'application/json',
      },
    });
    optionsEquipment.value = response.data.data.map((item)=>({
      value: item.id,
      label: item.name
    }))
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};

//获取分组
const getAlllocation = async () => {
  try {
    const response = await fetch(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAlllocation}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    optionsCustomRegion.value = data.data.map((item)=>({
      value: item.id,
      label: item.name
    }))
    return data.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//全部策略
const strategyAll = async () => {
  try {
    const response = await fetch(`${apiModule.apiBaseUrl}${apiModule.endpoints.strategyAll}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    optionsStrategy.value = data.data.map((item)=>({
      value: item.id,
      label: item.name
    }))

    return data.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};


// 当 dialogVisible 变化时初始化图表
watch(dialogVisible, async () => {

});
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.query-container {
  text-align: left;
  margin-bottom: 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
