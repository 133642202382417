        /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG
        */
import { createApp } from 'vue';
import ElementPlus from 'element-plus';

import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css'
import App from './App.vue';
import router from './router';
import ECharts from 'vue-echarts';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import apiModule from './APIConfig.js'; // 导入APIConfig.js

const app = createApp(App);

// 初始化APIConfig.js
apiModule.initialize().then(() => {
  console.log("APIConfig.js 初始化成功！");
  // 在初始化完成后再创建应用程序实例
  app.use(ElementPlus, {
    locale: zhCn,
  });
  app.use(router);
  app.component('v-chart', ECharts);
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.mount('#app');
}).catch((error) => {
  console.error("APIConfig.js 初始化失败:", error);
});
