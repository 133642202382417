        <!-- /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG

                 同比分析界面
        */ -->
<template>
  <div class="energy-report-container">
    <el-row :gutter="24">
      <!-- 左侧树形控件 -->
      <el-col :span="6">
        <el-card>
          <template v-slot:header>
            <span class="card-header">同比分析</span>
          </template>
          <!-- 树形控件，显示设备分组
          <el-tree
            ref="tree"
            :data="treeData"
            :props="defaultProps"
            highlight-current
            node-key="id"
            @node-click="handleNodeClick"
            default-expand-all
            :expand-on-click-node="false"
            show-checkbox
            @check-change="handleCheckChange"
          /> -->
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="分组" name="group">
              <!-- 按分组 -->
              <!-- 添加搜索输入框 -->
              <el-input
                placeholder="模糊搜索"
                v-model="searchQuery"
                class="tree-search"
                clearable
              />
              <el-tree
                ref="tree"
                :data="treeData"
                :props="defaultProps"
                highlight-current
                node-key="id"
                @node-click="handleNodeClick"
                default-expand-all
                :expand-on-click-node="false"
                show-checkbox
                @check-change="handleCheckChange"
                :filter-node-method="filterNode"
              />
              <!-- 按分组end -->
            </el-tab-pane>

            <el-tab-pane label="设备" name="equipment">
              <!-- 按设备 -->
              <el-select
                v-model="selectedDevices"
                multiple
                clearable
                collapse-tags
                placeholder="请选择设备"
                popper-class="custom-header"
                :max-collapse-tags="1"
                filterable
              >
                <template #header>
                  <div class="header-container">
                    <el-checkbox
                      v-model="checkAllDevices"
                      :indeterminate="indeterminateDevices"
                      @change="handleCheckAllDevices"
                    >
                      全选
                    </el-checkbox>
                    <el-radio-group
                      v-model="energyType"
                      size="small"
                      class="radio-group-right"
                      @change="fetchDeviceData"
                    >
                      <el-radio-button label="电" value="电" />
                      <el-radio-button label="水" value="水" />
                    </el-radio-group>
                  </div>
                </template>
                <el-option
                  v-for="device in devices"
                  :key="device.id"
                  :label="device.name"
                  :value="device.id"
                />
              </el-select>
              <!-- 按设备end -->
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>

      <!-- 右侧查询条件和表格 -->
      <el-col :span="18">
        <div class="query-container">
          <!-- 查询条件 -->
          <el-form :model="queryForm" inline>
            <el-form-item class="left-align">
              <!-- 用能类型 -->
              <el-select
                v-model="energyType"
                placeholder="选择用能类型"
                style="width: 200px; margin-right: 10px"
                @change="fetchDeviceData"
              >
                <el-option label="水" value="水"></el-option>
                <el-option label="电" value="电"></el-option>
              </el-select>
              <!-- 年份选择器 -->
              <el-date-picker
                v-model="selectedYear"
                type="year"
                placeholder="选择年"
                @change="updateYear"
              />
              <!-- 上一年，下一年按钮 -->
              <el-button @click="prevYear" style="margin-left: 5px"
                >上一年</el-button
              >
              <el-button @click="nextYear">下一年</el-button>
              <el-button
                type="primary"
                @click="fetchTableData"
                style="margin-left: 5px"
                >查询</el-button
              >
              <el-button type="primary" @click="exportToExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-col :span="24" v-if="showChart" class="chart-controls">
          <el-button-group>
            <el-button
              :type="yearlyBarButtonType"
              @click="toggleChartType('柱状', 'yearly')"
              >柱状</el-button
            >
            <el-button
              :type="yearlyLinButtonType"
              @click="toggleChartType('曲线', 'yearly')"
              >曲线</el-button
            >
          </el-button-group>
        </el-col>
        <!-- ECharts柱状图 -->
        <v-chart
          ref="chartRef"
          v-if="showChart"
          :option="yearChartOption"
          style="width: 100%; height: 400px"
        />

        <!-- 表格 -->
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column prop="month" label="月份"></el-table-column>
          <el-table-column
            prop="currentPeriodValue"
            label="本期"
          ></el-table-column>
          <el-table-column
            prop="samePeriodLastYearValue"
            label="同期"
          ></el-table-column>
          <el-table-column
            prop="yearOverYearGrowth"
            label="同比(%)"
          ></el-table-column>
          <el-table-column
            prop="cumulativeYearOverYearGrowth"
            label="累计同比"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from "echarts/components";
import axios from "axios";
import ApiConfig from "@/APIConfig.js";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
]);

export default {
  name: "ChartAnalysis",
  data() {
    return {
      searchQuery: "", // 搜索查询
      activeName: "group", //默认选中分组
      devices: [], // 新增: 设备数据
      selectedDevices: [], // 新增: 选中的设备
      checkAllDevices: false, // 新增: 设备全选
      indeterminateDevices: false, // 新增: 设备全选不确定状态
      showChart: false, // 图表初始隐藏
      yearlyBarButtonType: "primary", // 初始柱状图按钮类型
      yearlyLinButtonType: "default", // 初始曲线图按钮类型
      chartType: "bar", // 初始图表类型
      energyType: "电", //选中的用能类型
      selectedYear: "", // 选中的年份
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      queryForm: {
        date: "",
      },
      tableData: [], // 表格数据
      selectedNodes: [], // 选中的节点
      yearChartOption: {
        // ECharts图表配置
        title: {},
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "5%",
          containLabel: true,
        },
        legend: {
          data: ["本期", "同期"],
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} kW·h",
          },
        },
        series: [
          {
            name: "本期",
            type: "",
            data: [],
          },
          {
            name: "同期",
            type: "",
            data: [],
          },
        ],
      },
      columnConfig: {
        value: {
          getColumnIndex: function () {
            // Add your implementation here or provide a mock function for debugging
            return 0;
          },
        },
      },
      chartData: null, // 保存图表数据
    };
  },
  created() {
    this.loadTreeData();
    this.fetchDeviceData(); // 新增: 调用设备数据的获取方法
  },
  watch: {
    searchQuery(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    loadTreeData() {
      this.fetchTreeData();
    },
    toggleChartType(type, timeframe) {
      if (type === "柱状") {
        this.chartType = "bar";
        this.yearlyBarButtonType = "primary";
        this.yearlyLinButtonType = "default";
      } else if (type === "曲线") {
        this.chartType = "line";
        this.yearlyBarButtonType = "default";
        this.yearlyLinButtonType = "primary";
      }

      if (timeframe === "yearly") {
        this.updateChartOptions(this.chartData); // 使用当前数据更新图表
      }
    },
    // 获取树形结构数据
    async fetchTreeData() {
      try {
        this.loading = true;
        const response = await axios.get(
          ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAlllocation
        );
        this.treeData = this.convertToTree(response.data.data);
      } catch (error) {
        this.$message.error("获取区域数据失败");
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchDeviceData() {
      let params = {};
      params = {
        energyType: this.energyType,
        projectId: ApiConfig.projectId,
      };
      // 在切换用能类型之前清空已选设备
      this.selectedDevices = [];
      this.checkAllDevices = false;
      this.indeterminateDevices = false;
      try {
        const response = await axios.get(
          ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAllequipment,
          { params: params }
        );
        if (response.data.code === 0) {
          this.devices = response.data.data;
        } else {
          this.$message.error("获取设备数据失败");
        }
      } catch (error) {
        this.$message.error("获取设备数据失败");
        console.error(error);
      }
    },
    // 转换为树形结构数据
    convertToTree(data) {
      const map = {};
      data.forEach((item) => {
        map[item.id] = { ...item, children: [] };
      });
      const tree = [];
      data.forEach((item) => {
        if (item.parent_id === null) {
          tree.push(map[item.id]);
        } else {
          map[item.parent_id].children.push(map[item.id]);
        }
      });
      return tree;
    },
    // 处理复选框变化事件
    handleCheckChange() {
      this.selectedNodes = this.$refs.tree.getCheckedNodes().map((node) => ({
        id: node.id,
        treeNodeId: node.$treeNodeId,
        label: node.label, // 确保正确属性
      }));
      console.log("Selected nodes:", this.selectedNodes);
    },
    handleCheckAllDevices(val) {
      this.selectedDevices = val ? this.devices.map((device) => device.id) : [];
      this.indeterminateDevices = false;
    },
    // async fetchTableData() {
    //   this.selectedNodes = this.$refs.tree.getCheckedNodes();
    //   if (this.selectedNodes.length === 0) {
    //     this.$message.warning("请先选择一个或多个分组");
    //     return;
    //   }

    //   const requestData = {
    //     customRegionIds: this.selectedNodes.map((node) => node.id),
    //     energyType: this.energyType,
    //     year: this.selectedYear.getFullYear().toString(), // 确保year为yyyy格式的字符串
    //   };

    //   console.log("Request data:", requestData); // 输出请求数据

    //   try {
    //     const response = await axios.post(
    //       ApiConfig.apiBaseUrl + ApiConfig.endpoints.getYearYoyQuery,
    //       requestData
    //     );

    //     console.log("Response data:", response.data); // 输出响应数据

    //     const data = response.data.data;
    //     this.chartData = data; // 保存数据以便切换图表类型时使用
    //     // 将表格数据赋值给this.tableData
    //     if (data && data["table "]) {
    //       // 确保表格数据的键名正确
    //       this.tableData = data["table "].map((item) => ({
    //         month: item.month,
    //         currentPeriod: item.currentPeriodValue,
    //         samePeriod: item.samePeriodLastYearValue,
    //         yearOnYear: item.yearOverYearGrowth.toFixed(2),
    //         cumulativeYearOnYear: item.cumulativeYearOverYearGrowth.toFixed(2),
    //       }));
    //       this.showChart = true; // 显示图表
    //       this.updateChartOptions(data); // 更新图表选项
    //     } else if (data.length === 0) {
    //       const nodeNames = this.selectedNodes
    //         .map((node) => node.label)
    //         .join(", ");
    //       this.$message.warning(`${nodeNames} 暂无数据`);
    //       this.showChart = false; // 数据不存在时隐藏图表
    //     } else {
    //       console.error("Table data is missing in the response", data);
    //       this.showChart = false; // 数据不存在时隐藏图表
    //     }
    //   } catch (error) {
    //     this.$message.error("数据不存在");
    //     console.error(error);
    //     this.showChart = false; // 出错时隐藏图表
    //   }
    // },
    async fetchTableData() {
      const year = new Date(this.selectedYear).getFullYear();

      const body = {
        energyType: this.energyType,
        timeType: this.timeFrame,
        year: year,
      };

      let apiurl;

      if (this.activeName === "group") {
        this.selectedNodes = this.$refs.tree.getCheckedNodes();
        if (this.selectedNodes.length === 0) {
          this.$message.warning("请先选择一个或多个分组");
          return;
        }
        apiurl = ApiConfig.endpoints.getYearYoyQuery;
        body.customRegionIds = this.selectedNodes.map((node) => node.id);
      } else if (this.activeName === "equipment") {
        if (this.selectedDevices.length === 0) {
          this.$message.warning("请先选择一个或多个设备");
          return;
        }
        apiurl = ApiConfig.endpoints.getEquipmentYearYoyQuery;
        body.equipmentIds = this.selectedDevices;
      }

      console.log("Request body:", body);

      const url = `${ApiConfig.apiBaseUrl}${apiurl}`;

      try {
        const response = await axios.post(url, body);
        if (
          response.data.code === 0 &&
          response.data.message === "该类型设备数为0"
        ) {
          const nodeNames = this.selectedNodes
            .map((node) => node.label)
            .join(", ");
          this.$message.warning(`${nodeNames} 暂无数据`);
          return;
        }

        const data = response.data.data;
        this.tableData = data['table '];
        this.chartData = data; // 将原始数据保存到 chartData
        this.updateChartOptions(data);
        this.showNavigationButtons_year = this.timeFrame === "year";

        this.showChart = true; // 显示图表
      } catch (error) {
        console.error("API error:", error);
        this.$message.error("数据加载失败，请稍后重试");
      }
    },
    // transformData(data) {
    //   const transformedData = data["table "].map((item) => {
    //     const transformedItem = { deviceName: item.deviceName };

    //     // 遍历每个时间点的数据
    //     Object.entries(item).forEach(([key, value]) => {
    //       if (key !== "deviceName") {
    //         transformedItem[key] = !isNaN(parseFloat(value))
    //           ? parseFloat(value).toFixed(2)
    //           : "0.00";
    //       }
    //     });

    //     // 计算合计值
    //     transformedItem.total = Object.values(transformedItem)
    //       .filter((val) => !isNaN(val))
    //       .reduce((acc, val) => acc + parseFloat(val), 0)
    //       .toFixed(2); // 格式化为两位小数

    //     return transformedItem;
    //   });
    //   console.log("Headers:", this.headers);
    //   console.log("Transformed Data:", transformedData);
    //   return transformedData;
    // },
    updateChartOptions(data) {
      // 处理ECharts图表数据
      const currentPeriodData = data.barChart
        .filter((item) => item.type === "本期")
        .map((item) => item.value);

      const samePeriodData = data.barChart
        .filter((item) => item.type === "同期")
        .map((item) => item.value);

      const timeLabels = data.barChart
        .filter((item) => item.type === "本期")
        .map((item) => item.time);

      const unit = this.energyType === "水" ? "m³" : "kW·h";

      this.yearChartOption = {
        ...this.yearChartOption,
        xAxis: {
          type: "category",
          data: timeLabels,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: `{value} ${unit}`,
          },
        },
        series: [
          {
            name: "本期",
            type: this.chartType, // 根据chartType动态设置类型
            smooth: this.chartType === "line", // 确保曲线平滑
            data: currentPeriodData,
          },
          {
            name: "同期",
            type: this.chartType, // 根据chartType动态设置类型
            smooth: this.chartType === "line", // 确保曲线平滑
            data: samePeriodData,
          },
        ],
      };
      this.$nextTick(() => {
        this.$refs.chartRef.setOption(this.yearChartOption);
      });
    },
    // 切换到上一年
    prevYear() {
      const currentDate = new Date(this.selectedYear);
      if (!isNaN(currentDate.getTime())) {
        this.selectedYear = `${currentDate.getFullYear() - 1}`;
      }
      this.fetchTableData();
    },
    // 切换到下一年
    nextYear() {
      const currentDate = new Date(this.selectedYear);
      if (!isNaN(currentDate.getTime())) {
        this.selectedYear = `${currentDate.getFullYear() + 1}`;
      }
      this.fetchTableData();
    },
    // 导出数据到Excel
    async exportToExcel() {
      const year = new Date(this.selectedYear).getFullYear();

      const body = {
        energyType: this.energyType,
        year: year.toString(),
      };

      let apiurl;

      if (this.activeName === "group") {
        this.selectedNodes = this.$refs.tree.getCheckedNodes();
        if (this.selectedNodes.length === 0) {
          this.$message.warning("请先选择一个或多个分组");
          return;
        }
        apiurl = ApiConfig.endpoints.getYearYoyQueryExcel;
        body.customRegionIds = this.selectedNodes.map((node) => node.id);
      } else if (this.activeName === "equipment") {
        if (this.selectedDevices.length === 0) {
          this.$message.warning("请先选择一个或多个设备");
          return;
        }
        apiurl = ApiConfig.endpoints.getEquipmentYearYoyQueryExcel;
        body.equipmentIds = this.selectedDevices;
      }

      console.log("Request body for export:", body);

      const url = `${ApiConfig.apiBaseUrl}${apiurl}`;

      try {
        const response = await axios.post(url, body, { responseType: "blob" });

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `年同比数据_${this.selectedYear}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        this.$message.error("导出数据失败");
        console.error(error);
      }
    },
    resizeChart() {
      if (this.$refs.chartRef && this.$refs.chartRef.resize) {
        this.$refs.chartRef.resize();
      }
    },
  },
  /**
   * 在组件挂载后执行的生命周期钩子。
   * 主要用于初始化图表和处理窗口大小改变时的图表自适应。
   */
  mounted() {
    // 监听窗口大小改变事件，以调整图表大小
    window.addEventListener("resize", this.resizeChart);

    // 使用$nextTick确保DOM更新后设置图表选项
    this.$nextTick(() => {
      // 设置图表的初始选项
      this.$refs.chartRef.setOption(this.yearChartOption);
    });
  },
  /**
   * 在组件卸载之前移除窗口resize事件监听器。
   * 这个函数的目的是为了避免组件卸载后仍然执行resizeChart函数，
   * 导致潜在的内存泄漏问题。通过在组件生命周期的beforeUnmount钩子中调用，
   * 确保当组件不再被使用时，与其相关的事件监听器也被正确移除。
   */
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeChart);
  },
};
</script>

<style scoped>
.energy-report-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}

.query-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.left-align {
  display: flex;
  align-items: center;
}
.chart-controls {
  text-align: right;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
